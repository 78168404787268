import styled from 'styled-components';
import { DatePicker, Form, Radio, Button, Col } from 'antd';

export const ChartWrapper = styled.div`
	height: 75vh;
`;

export const ChartContainer = styled.div`
	height: calc((75vh - 80px) * 0.77);
	padding: 1rem;
	overflow: hidden;
`;

export const ChartContent = styled.div`
	width: 100%;
	height: 100%;

	> canvas {
		max-width: 100% !important;
		height: 100% !important;
	}
`;

export const FiltersContainer = styled.div`
	height: 75vh;
	background: white;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	overflow-y: auto;
`;

export const FilterGroup = styled.div`
	margin-bottom: 1rem;

	label {
		display: block;
		margin-bottom: 0.5rem;
		font-size: 0.875rem;
		color: #666;
	}

	select {
		width: 100%;
		padding: 0.5rem;
		border: 1px solid #ddd;
		border-radius: 4px;
		font-size: 0.875rem;
	}
`;

export const PeriodContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding-left: 3.5rem;
	width: 90%;
`;

export const CustomLabel = styled.span`
	font-size: 0.875rem;
	color: rgba(0, 0, 0, 0.88);
	height: 22px;
`;

export const GranularityContainer = styled.div`
	height: 100%;
	display: flex;
	margin-right: 3rem;
	align-items: flex-end;
`;

export const ButtonWrapper = styled.div`
	display: flex;
	align-items: flex-end;
	height: 100%;
	margin-right: 2rem;
`;

export const CustomButton = styled(Button)`
	margin-bottom: 0.2rem;
	border-radius: 0.5rem;
	height: 2.2rem;
`;

export const CustomColEnd = styled(Col)`
	display: flex;
	justify-content: flex-end;
`;

export const CustomFormItem = styled(Form.Item)`
	margin: 0;

	.ant-form-item-label {
		padding: 0;
	}

	.ant-form-item-control-input-content {
		display: flex;
		align-items: center;
	}
`;

export const RadioGroup = styled(Radio.Group)`
	display: flex;
	overflow: hidden;
	border-radius: 8px;
	border: 1px solid #d9d9d9;
	height: 2.5rem;

	.ant-radio-button-wrapper {
		width: 18%;
		height: 100%;
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: white;
		padding: 0 12px;

		span {
			white-space: nowrap;

			&:last-child {
				font-size: 0.9rem;
			}
		}
	}

	.ant-radio-button-wrapper:focus-within {
		color: #fff;
		box-shadow: none;
		background-color: #2f54eb;
	}

	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
		color: #fff;
		box-shadow: none;
		border-radius: 8px;
		background-color: #2f54eb;

		&::before {
			background: none;
		}
	}
`;

export const CustomRangePicker = styled(DatePicker.RangePicker)`
	width: 100%;
	height: 2.5rem;
	border-radius: 0.5rem;
	border: 1px solid #d9d9d9;

	.ant-picker-input {
		input {
			font-size: 1rem !important;
		}
	}
`;
