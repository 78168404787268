import styled from 'styled-components';
import { Button, Radio } from 'antd';

interface MosaicGridProps {
	columns: number;
	rows: number;
}

interface NavItemProps {
	active?: boolean;
}

interface MosaicCardProps {
	$color: string;
}

export const ContentWrapper = styled.div`
	width: 100%;
	height: auto;
	margin: 0 auto;
	background-color: #ffffff;
	padding: 10px;
	box-sizing: border-box;
	overflow: hidden;
	border-radius: 12px;
`;

export const HeadertWrapper = styled.div`
	width: 100%;
	margin: 0 auto;
	background-color: #ffffff;
	padding: 10px;
	padding-bottom: 0px;
	box-sizing: border-box;
	overflow: hidden;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
`;

export const MosaicGrid = styled.div<MosaicGridProps>`
	gap: 5px;
	width: 100%;
	height: 50vh;
	min-height: 50vh;
	max-height: 50vh;
	display: grid;
	grid-template-columns: repeat(${(props) => props.columns}, 1fr);
	grid-template-rows: repeat(${(props) => props.rows}, 1fr);
`;

export const MosaicCard = styled.div<MosaicCardProps>`
	display: flex;
	position: relative;
	cursor: pointer;
	align-items: center;
	border-radius: 5px;
	justify-content: center;
	min-height: 110px;
	background-color: ${({ $color }) => $color || '#8A8A8A'};

	span {
		color: #fff;
		padding: 10px;
		text-align: center;
		font-weight: bold;
		overflow: hidden;
		width: calc(100% - 130px);
		text-overflow: ellipsis;
	}

	div.legend {
		position: absolute;
		top: 6px;
		bottom: 6px;
		right: 10px;
		width: 50px;
		align-items: center;
		svg {
			margin-top: 2px;
			-webkit-filter: drop-shadow(-1px 0 0 rgba(255, 255, 255, 1)) drop-shadow(1px 0 0 rgba(255, 255, 255, 1))
				drop-shadow(0 -1px 0 rgba(255, 255, 255, 1)) drop-shadow(0 1px 0 rgba(255, 255, 255, 1));
			filter: drop-shadow(-1px 0 0 rgba(255, 255, 255, 1)) drop-shadow(1px 0 0 rgba(255, 255, 255, 1))
				drop-shadow(0 -1px 0 rgba(255, 255, 255, 1)) drop-shadow(0 1px 0 rgba(255, 255, 255, 1));

			&.count-0 {
				opacity: 0.4;
			}
		}
	}
`;

export const Header = styled.div`
	display: flex;
	padding-bottom: 10px;
	justify-content: start;
	border-bottom: 1px solid #26262666;
`;

export const CustomReportButton = styled(Button)`
	display: flex;
	font-weight: 600;
	margin-right: 10px;
	align-items: center;

	&.ant-btn-background-ghost.ant-btn-primary {
		color: #262626;
		border-color: #e6e6e6;
		background-color: #fff;

		&:active {
			color: #fff;
			border-color: #4f00eb;
			background-color: #4f00eb;
		}
	}
`;

export const StyledRadioButton = styled(Radio.Button)`
	border: 1px solid #d9d9d9;
	background-color: #fff;
	color: #000;
	margin: 10px;
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.3s;
	font-weight: 600;
	display: flex;
	padding: 0px 16px;

	&.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
		display: none;
	}

	&.ant-radio-button-wrapper:not(:first-child)::before {
		display: none;
	}

	&.ant-radio-button-wrapper {
		height: 2.2rem !important;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&:hover {
		border-color: #40a9ff;
		background-color: #e6f7ff;
	}

	&.ant-radio-button-wrapper-checked {
		color: white;
		border-color: #2f54eb;
		background-color: #2f54eb;
		border-radius: 4px;
	}

	&.ant-radio-button-wrapper:last-child {
		border-radius: 4px;
	}
`;

export const StyledRadioGroup = styled(Radio.Group)`
	display: flex;
`;

export const HeaderNavigation = styled.header`
	display: flex;
	align-items: center;
`;

export const NavigationWrapper = styled.div`
	background-color: #eaeefd;
	border-top-right-radius: 6px;
	border-top-left-radius: 6px;
	display: flex;
	align-items: center;
`;

export const BackButton = styled.button`
	background: none;
	border: none;
	font-size: 18px;
	cursor: pointer;
	display: flex;
	align-items: center;
	padding: 5px;
`;

export const NavItem = styled.span<NavItemProps>`
	background: ${(props) => (props.active ? '#2F54EB' : 'none')};
	color: ${(props) => (props.active ? 'white' : '#2F54EB')};
	font-weight: bold;
	border: none;
	font-size: 15px;
	cursor: pointer;
	padding: 5px;
	margin-left: 10px;
	border-top-right-radius: 6px;
	border-top-left-radius: 6px;
	transition: background-color 0.3s, color 0.3s;
	display: inline-flex;
	align-items: center;
`;

export const Separator = styled.span`
	margin: 0 8px;
	font-size: 16px;
	color: #2f54eb;
`;

export const CustomReportsSpan = styled.span`
	font-weight: lighter;
	margin-left: 5px;
	font-size: 12px;
`;

export const ReportContent = styled.div`
	display: flex;
	align-items: center;
`;

export const IconWrapper = styled.div`
	flex-shrink: 0;
	width: 20px;
	height: 20px;
	margin-right: 8px;

	display: flex;
	align-items: center;
	justify-content: center;

	svg,
	i {
		width: 100%;
		height: 100%;
		vertical-align: middle;
	}
`;

export const NameWrapper = styled.div`
	font-size: 15px;
	flex-grow: 1;
	display: flex;
	align-items: center;

	p {
		margin: 0;
		display: inline-flex;
		align-items: center;
	}
`;
