import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import { Button } from 'antd';
import { Title } from '@/components/Typography';
import { FilterCard, FilterCardProps } from './FilterCard';
import { CardsList } from './styles';
import { Filter } from './types';

export function Filters() {
	const location = useLocation();
	const history = useHistory();
	const filter = new URLSearchParams(location.search).get('filter') as Filter;

	const data: Array<Array<Omit<FilterCardProps, 'filter' | 'onChangeFilter'>>> = [
		[
			{
				name: 'effort',
				title: I18n.get('Effort'),
				type: 'single'
			},
			{
				name: 'reaction',
				title: I18n.get('Reaction'),
				type: 'single'
			},
			{
				name: 'vibration',
				title: I18n.get('Vibration'),
				type: 'single'
			},
			{
				name: 'beats_against',
				title: I18n.get('Beats against'),
				type: 'single'
			},
			{
				name: 'percussion',
				title: I18n.get('Percussion'),
				type: 'single'
			}
		],
		[
			{
				name: 'hand',
				title: I18n.get('Hands'),
				type: 'dualBodyPart'
			},
			{
				name: 'upper_arm',
				title: I18n.get('Shoulders/Arms'),
				type: 'dualBodyPart'
			},
			{
				name: 'trunk',
				title: I18n.get('Trunk'),
				type: 'bodyPart'
			},
			{
				name: 'neck',
				title: I18n.get('Neck'),
				type: 'bodyPart'
			},
			{
				name: 'hip',
				title: I18n.get('Lower members'),
				type: 'lowerMember'
			}
		]
	];

	function onChangeFilter(filter?: Filter) {
		const searchParams = new URLSearchParams(location.search);

		if (filter) {
			searchParams.set('filter', filter);
		} else {
			searchParams.delete('filter');
		}

		const url = `${location.pathname}?${searchParams.toString()}`;
		history.replace(url);
	}

	return (
		<React.Fragment>
			<Title level={4}>
				{I18n.get('Filters')}{' '}
				<Button type="link" onClick={() => onChangeFilter()}>
					{I18n.get('Clear')}
				</Button>
			</Title>
			{data.map((filters, index) => (
				<CardsList key={index}>
					{filters.map((option, index) => (
						<FilterCard key={index} {...option} filter={filter} onChangeFilter={onChangeFilter} />
					))}
				</CardsList>
			))}
		</React.Fragment>
	);
}
