import styled from 'styled-components';
import { Input, Select } from 'antd';

export const Container = styled.div`
	background: #fff;
	border-radius: 8px;
	padding: 20px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	height: 75%;
`;

export const Title = styled.h3`
	font-size: 16px;
	color: #1a1a1a;
	margin-bottom: 24px;
	font-weight: 500;
`;

export const FilterGroup = styled.div`
	margin-bottom: 1.5rem;

	&:last-child {
		margin-bottom: 0;
	}
`;

export const Label = styled.label`
	display: block;
	font-size: 14px;
	color: #666;
	margin-bottom: 8px;
`;

export const CustomInput = styled(Input)`
	border-radius: 6px !important;
`;

export const CustomSelect = styled(Select).attrs({
	virtual: false
})`
	width: 100%;
	border-radius: 6px !important;

	.ant-select-selector {
		height: 40px;
		padding: 8px 12px;
		border: 1px solid #e0e0e0;
		border-radius: 6px !important;
		background: #fff;
		font-size: 14px;
		color: #1a1a1a;
		cursor: pointer;
		outline: none;

		&:focus {
			border-color: #1890ff;
		}

		&:disabled {
			background: #f5f5f5;
			cursor: not-allowed;
		}
	}
`;
