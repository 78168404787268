import React from 'react';
import { Row, Col } from 'antd';
import { Title } from '@/components/Typography';
import { Mosaic } from './components/Mosaic';
import { Filters } from './components/Filters';
import { HistoryChart } from './components/HistoryChart';
import { AssessmentsChart } from './components/AssessmentsChart';

export function Dashboard() {
	return (
		<Row gutter={[0, 15]}>
			<Col span={24}>
				<Title level={3}>Overview</Title>
			</Col>
			<Col span={24}>
				<Row gutter={[30, 0]}>
					<Col span={24}>
						<Mosaic />
					</Col>
					<Col span={24}>
						<Filters />
					</Col>
					<Col span={24}>
						<AssessmentsChart />
					</Col>
					<Col span={24}>
						<HistoryChart />
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
