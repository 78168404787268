import type { Dictionary } from './types';

const dictionary: Dictionary = {
	'Segment analysis': 'Análise por segmento',
	Detailed: 'Detalhado',
	'Workstations total': 'Total de postos',
	'Workstation not analyzed': 'Postos não analisados',
	'Analyzed workstations': 'Postos analisados',
	'Jobs and reviews': 'Postos de trabalho e avaliações',
	'Temporary & Indeterminate': 'Temporário & Indeterminado',
	Nexus: 'Nexo',
	Certificates: 'Atestados',
	'Complaints and Certificates': 'Queixas e Atestados',
	'Complaints list': 'Lista de queixas',
	'Medical certificates list': 'Lista de atestados médicos',
	'Body part': 'Parte do corpo',
	'Complaint description': 'Descrição da queixa',
	'Certificate description': 'Descrição do atestado',
	'Complaint date': 'Data da queixa',
	'Certificate date': 'Data do atestado',
	'Initial date': 'Data inicial',
	'End date': 'Data final',

	'Please enter a person responsible': 'Por favor, insira um responsável',
	'Please enter a valid date': 'Por favor, insira uma data válida',
	'Please select a body part': 'Por favor, selecione uma parte do corpo',
	'Please select a workstation': 'Por favor, selecione um posto de trabalho',
	Files: 'Arquivos',
	'File deleted successfully': 'Arquivo excluído com sucesso',
	January: 'Janeiro',
	February: 'Fevereiro',
	March: 'Março',
	April: 'Abril',
	May: 'Maio',
	June: 'Junho',
	July: 'Julho',
	August: 'Agosto',
	September: 'Setembro',
	October: 'Outubro',
	November: 'Novembro',
	December: 'Dezembro',

	'All uploads and reports from this company will be deleted. This action cannot be undone. Do you wish to continue?':
		'Todos os uploads e relatórios desta empresa serão excluídos. Essa ação não pode ser desfeita. Você deseja continuar?',
	'No data': 'Não há dados',
	'Ergonomic quote': 'Cotação ergonômica',
	'Failed to fetch': 'Falha ao buscar',
	Appointment: 'Apontamento',
	'New Appointment': 'Novo Apontamento',
	MALE: 'Masculino',
	FEMALE: 'Feminino',
	Restriction: 'Restrição',
	TEMPORARY: 'Temporário',
	INDETERMINATE: 'Indeterminado',
	NOT_RESTRICT: 'Sem restrição',

	'Value should be less than 7 character': 'O valor deve ser menor que 7 caracteres',
	'Value should be more than 3 characters': 'O valor deve ser maior que 3 caracteres',

	Workers: 'Funcionários',
	'Workers management': 'Gestão de funcionários',
	'Successfully updated worker data': 'Dados do trabalhador atualizados com sucesso',
	'Worker successfully created': 'Trabalhador criado com sucesso',

	Active: 'Ativo',
	Disabled: 'Inativo',
	'Please enter a valid registration id': 'Insira um id de registro válido',

	'Corrupted file': 'Arquivo corrompido',
	'Extracted data': 'Dados extraídos',
	'Not processing': 'Não processado',
	Processing: 'Processando',
	Processed: 'Processado',
	'In queue': 'Em fila',

	'Export report': 'Exportar relatório',
	'There is no post with screwdriver': 'Não tem posto com parafusadeira/apertadeira',

	'Data not found': 'Dados não encontrados',

	'Requires actions': 'Requer ações',
	'No actions required': 'Não requer ações',

	'High Risk and Requires Action': 'Alto Risco e Requer ações',
	'Moderate risk and no action required': 'Risco Moderado e Não requer ações',
	'Low Risk and No Action Required': 'Baixo Risco e Não requer ações',

	'Intensity of work': 'Intensidade do trabalho',

	'Work organization': 'Organização do trabalho',
	'Are spontaneous pauses allowed during the workday?':
		'É permitido pausas espontâneas durante a jornada de trabalho?',
	'Is there a diversification of tasks in the performance of your functions?':
		'No desempenho de suas funções há diversificação de tarefas?',
	'Materials are always available at the workstation, avoiding rush, delay or overload?':
		'Os materiais sempre estão disponíveis no posto de trabalho evitando correria, atraso ou sobrecarga?',

	Result: 'Resultado',

	Complexity: 'Complexidade',
	'Easy to understand information': 'Informação de fácil compreensão',
	'Movements do not require high skill': 'Movimentos não exigem alta destreza',
	'Low level of attention required for long periods': 'Baixo nível de exigência de atenção por longos períodos',
	'Workstation is complex and high cognitive demand': 'Posto é complexo e de alta exigência cognitiva',
	'Workstation is simple and of low cognitive requirement': 'Posto é simples e de baixa exigência cognitiva',

	'Displacement ': 'Deslocamento ',
	'Squat ': 'Agachamento ',

	Cervical: 'Cervical',
	'Cervical spine': 'Coluna cervical',
	Torso: 'Tronco',
	'Left Shoulder/Arm': 'Ombro/Braço Esquerdo',
	'Right Shoulder/Arm': 'Ombro/Braço Direito',

	'Left lower arm': 'Antebraço esquerdo',
	'Right lower arm': 'Antebraço direito',
	'Left upper arm': 'Ombro esquerdo',
	'Right upper arm': 'Ombro direito',

	'Left fist': 'Punho Esquerdo',
	'Right fist': 'Punho direito',

	'No repeatability': 'Sem repetividade',
	'With repetition': 'Com repetividade',

	' x per hour': ' x por hora',
	'Without risk': 'Sem risco',
	'Action Level': 'Nível de ação',
	'Complementary factors': 'Fatores complementares',
	'Percussion tools ': 'Ferramentas de percussão ',
	'Hit against ': 'Batidas contra ',
	'Present Risk': 'Risco Presente',

	'Not identified': 'Não identificado',
	'Low risk': 'Risco baixo',
	'Moderate risk': 'Risco médio',
	'Medium risk': 'Risco médio',
	'High risk': 'Risco alto',
	Moderate: 'Moderado',
	'Total avaliations': 'Avaliações totais',
	'Total workstations': 'Total de postos',

	'CNHI Ergonomics System': 'Sistema de Ergonomia CNHI',
	'Global Rating': 'Classificação Global',

	'Worker report': 'Relato do operador',
	'Enter worker report below': 'Insira abaixo o relato do operador',

	'Up to 10 steps per minute': 'Até 10 passos por minuto',
	'15 to 25 steps per minute': 'de 15 a 25 passos por minuto',
	'Above 25 steps per minute': 'Acima de 25 passos por minuto',

	'Inform below the displacement of the worker during the workday':
		'Informar abaixo o deslocamento do operador durante a jornada de trabalho',

	'Regulation notes': 'Notas de regulação',
	'Spontaneous breaks are allowed during the workday (e.g. drinking water, drinking coffee, going to the bathroom)':
		'É permitido pausas espontâneas durante a jornada de trabalho (ex.: tomar água, beber café, ir ao banheiro)',
	'In the performance of their functions, there is diversification of tasks':
		'No desempenho de suas funções há diversificação de tarefas',
	'Materials are always available at the workstation, avoiding rush, delay or overload':
		'Os materiais sempre estão disponíveis no posto de trabalho evitando correria, atraso ou sobrecarga',
	'Work intensity: give a score from 1 to 7 (1 being a little work and 7 being very intense work)':
		'Intensidade do trabalho: dê uma nota de 1 a 7 (sendo 1 pouco trabalho e 7 trabalho intensíssimo)',

	Wrist: 'Punho',
	'Hit against': 'Batidas contra',
	'Enter job information below': 'Insira abaixo informações sobre o posto de trabalho',
	'During the operation, the operator taps his hands to adjust parts or components.':
		'O operador realiza durante a operação batidas com as mãos para fazer ajuste de peças ou componentes.',

	'Add an additional video for wrist analysis': 'Adicione um vídeo complementar para análise do punho',
	'Do not include in the analysis': 'Não incluir na análise',
	'Include external video': 'Incluir vídeo externo',
	'Enter video name': 'Digite o nome do vídeo',

	'Sledgehammer and/or taps are hits from hard hits (except hit adjustments)':
		'Marreta e/ou tas so batidas de batidas fortes (exceto ajustes de batida)',
	'Do not use': 'Não utiliza',
	'Light - Quiet': 'Leve - Tranquilo',
	'Medium - Some effort is perceived (defined)': 'Médio - Percebe-se algum esforço (definido)',
	'Heavy - Clear effort: no change in facial expression': 'Pesado - Esforço nitido: sem mudança de expressão facial',
	'Very heavy - Sharp effort: changes facial expression': 'Muito pesado - Esforço nitido: muda a expressão facial',
	'Next. maximum - Use of trunk and limbs to generate greater force':
		'Próximo. máximo - Uso do tronco e membros para gerar força maior',

	// ------- //
	'Workstation Information': 'Informação de trabalho',
	'Select a workstation': 'Selecione o posto de trabalho',

	'Acceptable Risk': 'Aceitável',
	'Low Risk': 'Leve',
	'Medium Risk': 'Alto',
	'High Risk': 'Extremo',

	'Enter operator difficulty control below': 'Insira abaixo o controle de dificuldade do operador',
	'Control Difficulty': 'Dificuldade de controle',
	'Select a cost center': 'Selecione o centro de custo',

	'Enter data about vibration in employee activity below':
		'Insira abaixo os dados sobre a vibração na atividade do funcionário, caso não tenha não é necessário preencher',

	"Enter below the data on the employee's reaction in the activity":
		'Insira abaixo os dados sobre a reação do colaborador na atividade',
	'Insert a new reaction': 'Insira uma nova reação',
	'Insert a new vibration': 'Insira uma nova vibração',

	"Enter below the data of the employee's effort in the activity":
		'Insira abaixo os dados sobre o esforço do colaborador na atividade',

	'Insert a new effort': 'Insira um novo esforço',
	'Cost center': 'Centro de custo',
	'Enter a workstation': 'Insira o posto de trabalho',

	'Enter the data related to the selected video below': 'Insira abaixo os dados relacionados ao vídeo selecionado',

	'Current password': 'Senha atual',
	'New password': 'Nova senha',
	'Confirm password': 'Confirmar senha',

	'Create company': 'Criar empresa',
	'Create industrial site': 'Criar planta industrial',

	'Create a new organization': 'Criar nova empresa',
	'Create a new company': 'Criar nova planta industrial',
	'Company created successfully': 'Empresa criada com sucesso',

	Staff: 'Quadro de colaboradores',
	'Enter the Company Activities': 'Insra o CNAE',

	'Insert a production line': 'Insira a linha de produção',
	'Add new range': 'Adicionar nova banda',
	'industrial site': 'planta industrial',
	'Industrial Site': 'Planta industrial',
	'Industrial site': 'Planta industrial',
	'Select a industrial site': 'Selecione a planta industrial',

	'Production line': 'Linha de produção',
	'Select a production line': 'Selecione a linha de produção',

	User: 'Usuário',
	Worker: 'Funcionário',
	Name: 'Nome',
	Role: 'Tipo',
	Code: 'Código',
	Save: 'Salvar',
	Loading: 'Carregando',
	Register: 'Matrícula',
	Registers: 'Matrículas',
	PWD: 'PCD',
	PWDs: 'PCDs',
	Gender: 'Gênero',
	Women: 'Mulheres',
	Restrict: 'Restrito',
	Restricts: 'Restritos',
	Yes: 'Sim',
	No: 'Não',
	Male: 'Masculino',
	Female: 'Feminino',
	Temporary: 'Temporário',
	Indeterminate: 'Indeterminado',
	Undetermined: 'Indeterminado',
	'Without restriction': 'Sem restrição',
	'Not restrict': 'Não restrito',
	Both: 'Ambos',
	'Medical Certificate': 'Atestado Médico',
	'Update medical certificate': 'Editar atestado médico',
	'Delete medical certificate': 'Excluir atestado médico',
	'Do you want to delete this medical certificate?': 'Você quer excluir esse atestado médico?',
	'Update complaint': 'Editar queixa',
	'Delete complaint': 'Excluir queixa',
	'Do you want to delete this complaint?': 'Você quer excluir essa queixa?',
	'Fill the informations of the complaint:': 'Preencha as informações da queixa:',
	'Fill the informations of the medical certificate:': 'Preencha as informações do atestado médico:',
	'Fill the information to be updated:': 'Preencha as informações que deseja modificar:',
	'CID-M': 'CID-M',
	'Select the CID-M': 'Selecione o CID-M',
	'Send the CSV file to upload': 'Envie o arquivo CSV para upload',

	'Add record': 'Adicionar registro',
	Complaints: 'Queixas',
	'Complaints with nexus': 'Queixas com nexo',
	'Complaints without nexus': 'Queixas sem nexo',
	'Complaint created successfully': 'Queixa criada com sucesso',
	'Complaint updated successfully': 'Queixa editada com sucesso',
	'Complaint deleted successfully': 'Queixa excluída com sucesso',
	'Medical certificate created successfully': 'Atestado médico criado com sucesso',
	'Medical certificate updated successfully': 'Atestado médico editado com sucesso',
	'Medical certificate deleted successfully': 'Atestado médico excluído com sucesso',
	'Add complaint or certificate': 'Adicionar queixa ou atestado',
	'Delete worker': 'Excluir funcionário',

	'Tax Code': 'CNPJ',
	'Enter a Tax Number': 'Insira o CNPJ',
	'Insert a valid Tax Number': 'Insira um CNPJ válido',

	Companies: 'Empresas',
	'Create new company': 'Criar nova empresa',
	'Companies - Industrial sites': 'Empresas - Plantas industriais',

	'Create new user': 'Criar novo usuário',
	'Enter a email': 'Insira um email',
	'Select a role': 'Selecione um tipo',
	'User created successfully': 'Usuário criado com sucesso',
	'User changed successfully': 'Usuário alterado com sucesso',
	'Do you want to delete this user?': 'Você quer deletar esse usuário?',

	'Create new worker': 'Criar novo funcionário',
	'Worker created successfully': 'Funcionário criado com sucesso',
	'Worker changed successfully': 'Funcionário alterado com sucesso',
	'Update worker': 'Atualizar funcionário',
	'Select an option': 'Selecione uma opção',

	'Enter a name': 'Insira um nome',
	'Insert short description': 'Insira uma breve descrição',
	'Please enter a valid name': 'Insira um nome válido',
	'Please enter a valid register': 'Insira uma matrícula válida',

	'Description (optional)': 'Descrição (opcional)',
	'Personalize bands of risk': 'Personalize faixas de risco',

	'Full name': 'Nome completo',
	'User type': 'Tipo de usuário',
	Document: 'Documento',
	'Street number': 'Número',

	'Change password': 'Alterar senha',
	'Enter your current password': 'Digite sua senha atual',
	'Enter your new password': 'Digite sua nova senha',
	'Password must be at least 6 characters long': 'A senha deve ter pelo menos 6 caracteres',
	'Repeat your new password': 'Repita sua nova senha',
	"The two passwords don't match": 'As senhas não são iguais',

	'Current plan': 'Plano atual',
	'Subscription type': 'Tipo de assinatura',
	Recurrence: 'Recorrência',
	'Expiration date': 'Data de expiração',
	'Active users': 'Usuários ativos',
	'Processed videos': 'Vídeos processados',
	'Processing time': 'Tempo de processamento',

	'Risk ranges': 'Faixas de risco',

	'Local application': 'Local de aplicação',
	'Units to be analyzed': 'Unidades a serem analisadas',
	'Unit to be analyzed': 'Unidade a ser analisada',
	'Select the unit that will be included in the review': 'Selecione as unidades que serão incluídas na revisão',
	Factory: 'Fábrica',

	'Production volume': 'Volume de produção',
	'Select the industrial site to be analyzed': 'Selecione a planta industrial a ser analizadas',

	'Production Volume': 'Volume de produção',
	Investments: 'Investimentos',
	'Enter production volumes below': 'Insira os volumes de produção abaixo',
	'Inform below the investments in ergonomics': 'Informe abaixo os investimentos em ergonomia',

	Date: 'Data',
	'Date of reference': 'Data de referência',
	'Date of the report': 'Data do relatório',

	'Ergonomic analysis of work': 'Análise ergonômica do trabalho',
	Apresentation: 'Apresentação',
	'Plant Information': 'Informações da planta',

	'Insert a company name': 'Insira o nome da fantasia',
	'Company name': 'Nome fantasia',

	'Risk degree': 'Grau de risco',
	Activity: 'Atividade',

	Localization: 'Localização',
	Address: 'Endereço',
	Zipcode: 'CEP',
	District: 'Bairro',
	City: 'Cidade',
	State: 'Estado',
	Country: 'País',

	Administrative: 'Administrativo',
	'Work Shift': 'Turno de Trabalho',
	'Work shift': 'Turno de trabalho',
	'Select the work shift': 'Selecione o turno de trabalho',
	'Business informations not found': 'Informações comerciais não encontradas',

	Operational: 'Operacional',

	'Direct hourly worker': 'Horista direto',
	'Indirect hourly worker': 'Horista indireto',

	'Monthly worker': 'Mensalista',
	'Normal working hours': 'Horário normal de trabalho',

	'Invalid current password': 'Senha atual inválida',
	'Password changed successfully': 'Senha alterada com sucesso',

	// AET
	'Include checklists related to this analysis below': 'Inclua abaixo as checklists relacionadas a essa análise',

	'Select the body parts to display': 'Selecione as partes do corpo a serem exibidas',

	'Graphs of selected body parts': 'Gráficos de partes do corpo selecionadas',
	'Non-standard scale': 'Escala não padronizada',

	Qualification: 'Qualificação',

	// Bodyparts
	'Lower members': 'Membros inferiores',

	'Body parts': 'Partes do corpo',
	'Left elbow': 'Cotovelo esquerdo',
	'Right elbow': 'Cotovelo direito',
	'Left shoulder': 'Ombro esquerdo',
	'Right shoulder': 'Ombro direito',
	'Left knee': 'Joelho esquerdo',
	'Right knee': 'Joelho direito',
	'Left ankle': 'Tornozelo esquerdo',
	'Right ankle': 'Tornozelo direito',
	Squat: 'Agachamento',
	Displacement: 'Deslocamento',
	Hip: 'Quadril',

	// Buttons
	Next: 'Próximo',
	Previous: 'Voltar',

	/* Nomenclature v2 */

	'Left hand': 'Mão esquerda',
	'Right hand': 'Mão direita',
	'Video description': 'Descrição de vídeo',
	Below: 'Abaixo de',
	Above: 'Acima de',
	'Select the data visualization model': 'Selecione o modelo de visualização de dados',

	'Select the desired option': 'Selecione a opção desejada',

	Checklist: 'Checklist',
	'Movement by score': 'Percentual de Exposição',
	'Frequency and duration': 'Frequência e duração',
	'Not assigned': 'Não atribuido',

	'Date of colletion': 'Data de coleta',

	Informations: 'Informações',
	Parameters: 'Parâmetros',
	Diagnostic: 'Diagnóstico',

	'Select a sector': 'Selecione um setor',
	'Insert a workstation': 'Insira um posto de trabalho',

	'Select a risk band': 'Selecione uma faixa de risco',
	'Insert the date of collection': 'Insira a data da coleta',
	'Force/Charge movement data': 'Dados sobre movimento com Força/Carga',
	Repetitions: 'Repetições',
	Force: 'Força',
	Legs: 'Pernas',

	'Load less than 2 kg (intermittent)': 'Carga menor de 2 kg (intermitente)',
	'Load from 2 kg up to 10 kg (intermittent)': 'Carga de 2 kg a 10 kg (intermitente)',
	'Load from 2 kg up to 10 kg (static or repetitive)': 'Carga de 2 kg a 10 kg (estático ou repetitivo)',
	'Load more than 10 kg of repetitive load or blows': 'Carga maior 10 kg de carga repetitivos ou pancadas',

	'Legs and feet supported and with equal load distribution':
		'As pernas e pés apoiados e com igual distribuição de carga',
	'Legs without support': 'Pernas sem apoio',

	'Analysis results': 'Resultados da análise',
	'Job information': 'Informação de trabalho',
	'Range of Risk': 'Faixa de risco',
	'Average exposure of the workplace': 'Média de exposição do posto de trabalho',
	'File data': 'Dados do arquivo',
	File: 'Arquivo',
	'Include conclusion': 'Incluir conclusão',
	'Include signature': 'Incluir assinatura',
	'Insert conclusion': 'Inserir conclusão',

	'Not found': 'Não encontrado',
	Acceptable: 'Aceitável',
	Low: 'Baixo',
	Medium: 'Médio',
	Light: 'Leve',
	Average: 'Médio',
	High: 'Alto',
	Invalid: 'Inválido',

	Safe: 'Aceitável',
	Info: 'Leve',
	Warning: 'Atenção',
	Danger: 'Extremo',

	'Warning!': 'Atenção!',

	'Ergonomic grade': 'Cotação ergonômica',

	'Corrective action not required': 'Ação corretiva não necessária',
	'Corrective action as soon as possible': 'Ação corretiva o mais rápido possível',
	'High priority corrective action': 'Ação corretiva de alta prioridade',
	'Immediate corrective action': 'Ação corretiva imediata',

	'Unable to show results': 'Não foi possível mostrar os resultados',

	/* Nomenclature */
	Organization: 'Organização',
	Company: 'Empresa',
	Sector: 'Setor',
	Line: 'Linha',
	Workstation: 'Posto de trabalho',

	Size: 'Tamanho',
	'Upload date': 'Data de envio',
	Period: 'Período',

	Status: 'Status',
	Effort: 'Esforço',
	Reaction: 'Reação',
	Vibration: 'Vibração',
	'Beats against': 'Batidas contras',
	Percussion: 'Percussão',
	Hands: 'Mãos',
	'Shoulders/Arms': 'Ombros/Braços',
	Trunk: 'Tronco',
	'Lumbar spine': 'Coluna lombar',
	Backbone: 'Coluna dorsal',
	Neck: 'Cervical',
	Left: 'Esquerda',
	Right: 'Direita',
	Frequency: 'Frequência',
	Duration: 'Duração',
	Description: 'Descrição',
	Actions: 'Ações',
	Exit: 'Sair',
	Filters: 'Filtros',

	/* Commons button */
	Create: 'Criar',
	Back: 'Voltar',
	Clear: 'Limpar',
	'Clear filter': 'Limpar filtro',
	Send: 'Enviar',
	Confirm: 'Confirmar',
	Search: 'Buscar',
	//---------------

	/* Login - Page */
	// Placegolder's
	'E-mail': 'E-mail',
	Password: 'Senha',

	// Validation's errors inputs
	'Enter your email': 'Insira seu e-mail',
	'Enter a valid email': 'Insira um e-mail válido',
	'Enter your password': 'Insira sua senha',
	'Invalid email or password': 'E-mail ou senha inválidos',

	// 	// Forgot password
	'Forgot your password?': 'Esqueceu sua senha?',
	'We will send you an email to retrieve your password, rest assured :)':
		'Iremos enviar um e-mail para você recuperar sua senha, fique tranquilo :)',
	'Back to login': 'Voltar para o login',
	'Please enter a valid email address': 'Insira um e-mail válido',
	'Enter an email': 'Insira um e-mail',
	'Oops... Something went wrong!': 'Ops... Algo deu errado!',
	'Operation performed successfully': 'Operação realizada com sucesso',
	'Check your email inbox': 'Verifique sua caixa e-mails',

	'Click here to continue': 'Clique aqui para continuar',
	'Create new password': 'Crie uma nova senha',
	'Your new password must be different from previous used passwords':
		'Sua nova senha deve ser diferente das senhas usadas anteriormente',
	'Confirm new password': 'Confirme a nova senha',
	'The two passwords that you entered do not match': 'As duas senhas que você digitou não coincidem',

	// Button
	Login: 'Login',
	'Remember me': 'Lembre de mim',
	'Forgot password': 'Esqueceu a senha',
	/* ********************************************** */

	/* Container */
	// Selector project
	'Select the desired company below': 'Selecione a empresa desejada abaixo',
	'Select the desired industrial site below': 'Selecione a planta industrial desejada abaixo',

	// SideMenu
	Dashboard: 'Dashboard',
	'New Dashboard': 'Novo Dashboard',
	Upload: 'Upload',
	Reports: 'Relatórios',
	'All files': 'Todos os arquivos',
	'EI Files': 'Arquivos EI',
	'EHS Files': 'Arquivos EHS',
	EAW: 'AET',
	Management: 'Gestão',
	Settings: 'Configurações',
	'Health Matrix': 'Matriz Saúde',
	Score: 'Score',
	'Score by workstation': 'Score por posto',
	'Score by line': 'Score por linha',
	'Medical certificates': 'Atestados médicos',
	'Medical Certificates': 'Atestados Médicos',
	'Med. certificates': 'Atestados',
	'Score value: (complaints with nexus * 50) + (certificates * 10) + ergonomic grade':
		'Cálculo de Score: (queixas com nexo * 50) + (atestados * 10) + cotação ergonômica',

	'My account': 'Minha conta',
	Preferences: 'Preferências',
	'Manage users': 'Gerenciar usuários',
	'Manage companies': 'Gerenciar empresas',

	'Unit overview': 'Visão geral da unidade',
	'There is nothing to access.': 'Não há nada para acessar.',

	Cancel: 'Cancelar',
	'Update user': 'Editar usuário',

	// Manage Companies
	'Total Industrial Sites': 'Total de plantas industriais',
	'Date of creation': 'Data de criação',
	Action: 'Ações',
	'Manage industrial site': 'Gerenciar planta industrial',
	'Update company name': 'Atualizar nome da empresa',
	'Delete company': 'Remover empresa',
	Close: 'Fechar',
	'Heads up! This organization is not empty': 'Atenção! Esta organização não está vazia',
	'To continue remove companies belonging to this organization.':
		'Para continuar remova as empresas pertencentes a esta organização.',
	'Organization created successfully': 'Organização criada com sucesso',
	'All companies in this organization will be deleted, along with their respective uploads and reports. Do you wish to proceed?':
		'Todas as empresas nessa organização serão excluídas, incluindo seus respectivos uploads e relatórios. Deseja continuar?',
	'Organization name': 'Nome da organização',
	'Edit organization': 'Editar organização',
	'Edit industrial site': 'Editar planta industrial',
	'Edit sector': 'Editar setor',
	'Edit production line': 'Editar linha de produção',
	'Edit workstation': 'Editar posto de trabalho',
	'Edit product': 'Editar produto',
	'Updated informations': 'Informações atualizadas',
	'Failed to update information': 'Falha em atualizar informações',
	'Error editing company name': 'Erro ao editar o nome da empresa',
	'Organization updated successfully': 'Organização atualizada com sucesso',
	'Organization deleted successfully': 'Organização removida com sucesso',
	'Data created successfully': 'Dados criados com sucesso',
	'Data updated successfully': 'Dados atualizados com sucesso',
	'Data deleted successfully': 'Dados removidos com sucesso',
	'All data will be deleted, along with their respective uploads, reports and contents. Do you wish to proceed?':
		'Todos os dados serão excluídos, juntamente com seus respectivos uploads, relatórios e conteúdos. Você deseja continuar?',
	'Please, enter a valid industrial site name': 'Por favor, digite uma planta industrial válida',
	'Please, select a industrial site': 'Por favor, selecione uma planta industrial',
	'Industrial site name': 'Nome da planta industrial',
	'Create sector': 'Criar setor',
	'Sector name': 'Nome do setor',
	'Please, enter a valid sector name': 'Por favor, digite um setor válido',
	'Please, select a sector': 'Por favor, selecione um setor',
	'Create production line': 'Criar linha de produção',
	'Production line name': 'Nome da linha de produção',
	'Please, enter a valid production line name': 'Por favor, digite uma linha de produção válida',
	'Please, select a production line': 'Por favor, selecione uma linha de produção',
	'Create workstation': 'Criar posto de trabalho',
	GHE: 'GHE',
	'Please, select a GHE': 'Por favor, selecione um GHE',
	'Please enter a valid GHE': 'Por favor, digite um GHE válido',
	'Select a GHE': 'Selecione um GHE',
	'Workstation name': 'Nome do posto de trabalho',
	'Please, enter a valid workstation name': 'Por favor, digite um posto de trabalho válido',
	'Please, select a workstation': 'Por favor, selecione um posto de trabalho',
	Product: 'Produto',
	'Create product': 'Criar produto',
	'Product name': 'Nome do produto',
	'Please, enter a valid product name': 'Por favor, digite um produto válido',
	'Select a product': 'Selecione um produto',
	'Select the product': 'Selecione um produto',
	'Please, select a product': 'Por favor, selecione um produto',
	'Products in workstation': 'Produtos do posto de trabalho',
	'Products in line': 'Produtos da linha',
	'Manage products': 'Gerenciar produtos',

	/*************/

	// Upload
	'Send video': 'Enviar vídeo',
	'Add files or drag': 'Adicionar arquivos ou arraste',
	'Drop files here': 'Solte os arquivos aqui',
	Blur: 'Desfoque',

	'Are you sure?': 'Tem certeza?',
	'You are being redirected': 'Você está sendo redirecionado',
	'Are you sure you want to quit? You have an upload in process.':
		'Tem certeza que deseja sair? Você tem um upload em processo',

	// Upload filter
	'Select a organization': 'Selecione uma organização',
	'Select a company': 'Selecione uma empresa',
	'Select the sector': 'Selecione o setor',
	'Select a line': 'Selecione a linha',
	'Select the workstation': 'Selecione a estação de trabalho',

	// userAccess.js:
	'User not found': 'Usuário não encontrado',
	'This action could not be performed': 'Essa ação não pôde ser realizada',

	// constants.js:
	created: 'criado',
	processing: 'processando',
	finished: 'finalizado',
	failure: 'falha',
	'Session expired': 'Sessão expirada',
	'Unauthorized requisition': 'Requisição não autorizada',
	'Authorization header is missing': 'Header de autorização não encontrado',
	'Check the parameters entered': 'Verifique os dados inseridos',
	'Failed to query the database': 'Falha realizando query no banco de dados',
	'Failed to download EAW report': 'Falha no download de relatório AET',
	'Unsupported file': 'Arquivo não suportado',
	'Corrupted video': 'Vídeo corrompido',
	'File not found': 'Arquivo não encontrado',
	'Failed to read sent file': 'Erro lendo arquivo enviado',
	'Failed to save file': 'Falha ao salvar arquivo',
	'Failed to create download url': 'Falha ao criar url de download',
	'Already registered user': 'Usuário já registrado',
	'Failed to create customer plan': 'Falha ao criar plano de cliente',
	'Failed to create customer transaction': 'Falha ao criar transação de cliente',
	'Customer transaction not found': 'Transação de cliente não encontrada',
	'Customer transaction already completed': 'Transação de cliente já completada',
	'Product not found': 'Produto não encontrado',
	'Failed to create customer credits': 'Falha ao criar créditos de cliente',
	'Plan not found': 'Plano não encontrado',
	'Processed minutes limit reached': 'Minutos de processamento esgotados',
	'Invalid event': 'Evento inválido',
	'Customer not found': 'Cliente não encontrado',
	'Unregistered customer': 'Cliente não registrado',
	'Already registered customer': 'Cliente já registrado',
	'Failed to create customer': 'Falha ao criar cliente',
	'Failed to update customer': 'Falha ao atualizar cliente',
	'Failed to upload file to storage': 'Falha ao fazer upload de arquivo',
	'Failed to create file signature': 'Falha ao criar assinatura de arquivo',
	'Reply queue communication failure': 'Falha de comunicação na fila de resposta',

	// file.js
	'Failed to fetch the requested video': 'Falha ao buscar vídeo requisitado',
	'Failure to save the sent file.': 'Falha ao salvar arquivo enviado.',
	'Failure to communicate with the processing pool': 'Falha de comunicação com a fila de processamento',
	'File not ready': 'Arquivo não está pronto',
	'Internal error': 'Erro interno',
	'Internal server error': 'Erro de servidor interno',
	'Failed to upload file': 'Erro em upload de arquivo',
	'Failed to save upload information': 'Falha ao salvar informações de upload',

	// plan.js
	yearly: 'anual',
	monthly: 'mensal',
	'Update signature difference': 'Atualizar diferença de assinatura',
	'Voucher not found': 'Voucher não encontrado',
	'Recurrence plan not found': 'Plano de recorrência não encontrado',
	'It was not possible to cancel the contracted plan': 'Não foi possível cancelar o plano contratado',
	'Failed to cancel user service plan': 'Falha ao cancelar plano de serviço do usuário',
	'Payment data not found': 'Dados de pagamento não encontrados',
	'Failed to cancel current subscription': 'Falha ao cancelar assinatura atual',
	'Failed to perform transaction': 'Falha ao realizar transação',
	'Failed to save payment transaction': 'Falha ao salvar transação de pagamento',

	// userManagement.js
	'You cannot delete your own account.': 'Você não pode deletar sua própria conta',
	'User does not belong to your plan.': 'Usuário não pertence ao seu plano.',
	'User master not found.': 'Usuário master não encontrado.',
	'Organization not found': 'Organização não encontrada',
	'Company not found': 'Empresa não encontrada',

	// enum.js
	neck: 'Cervical',
	left_lower_arm: 'Cotovelo esquerdo',
	right_lower_arm: 'Cotovelo direito',
	left_upper_arm: 'Ombro esquerdo',
	right_upper_arm: 'Ombro direito',
	left_knee: 'Joelho esquerdo',
	right_knee: 'Joelho direito',
	left_ankle: 'Tornozelo esquerdo',
	right_ankle: 'Tornozelo direito',
	hip: 'Quadril',
	left_hand: 'Punho esquerdo',
	right_hand: 'Punho direito',
	success: 'sucesso',
	warning: 'aviso',
	error: 'erro',
	'You are unauthorized to access the requested resource': 'Você não é autorizado a acessar esse recurso',
	'Check the header parameters entered': 'Verifique os parâmetros inseridos',
	'Invalid Token': 'Token inválido',
	'Expired token': 'Token expirado',
	'Sector not found': 'Setor não encontrado',
	'Workstation not found': 'Posto não encontrado',
	'Line not found': 'Linha não encontrada',
	'Checklist not found': 'Checklist não encontrada',
	'Checklist already consolidated': 'Checklist já consolidada',
	'Results not found': 'Resultado não encontrado',
	'Effort not found': 'Esforço não encontrado',
	'Vibration not found': 'Vibração não encontrada',
	'Range risk not found': 'Faixa de risco não encontrada',
	'Failed to fetch risk tracks': 'Falha ao buscar faixa de risco',
	'Failed to read file': 'Falha ao ler arquivo',
	'File not yet processed': 'Arquivo ainda não processado',
	'Failed to read angles': 'Falha ao ler ângulos',
	'Master user not found': 'Usuário master não encontrado',
	'Potential customer not found': 'Cliente em potencial não encontrado',
	'Customer already registered': 'Usuário já cadastrado',
	'Invalid voucher': 'Voucher inválido',
	'Invalid recurrence': 'Recorrência inválida',
	'Plan already canceled': 'Plano já foi cancelado',
	'Invalid subscription type': 'Tipo de assinatura inválido',
	'It is necessary to spend a day after contracting the service to change the plan':
		'É necessário passar um dia após a contratação do serviço para alterar o plano.',
	'Choose a plan different from your current plan': 'Escolha um plano diferente do seu plano atual',
	'It is not possible to downgrade the annual plan': 'Não é possivel reduzir o plano anual',
	'Failed to decode payload': 'Falha ao decodificar o payload',
	'Plan expiration date not found': 'Data de expiração do plano não encontrada',
	'Document already registered': 'Documento já cadastrado',
	'Customer information not found': 'Informação de cliente não encontrada',
	'Failed to create user plan': 'Falha ao criar plano de usuário',
	'Customer plan not found': 'Plano do cliente não encontrado',
	'Maximum registered users': 'Número máximo de usuários cadastrados',
	'Failed to create user': 'Falha ao criar usuário',
	'User already registered': 'Usuário já cadastrado',
	'Organization still has registered companies': 'Organização ainda tem empresas cadastradas',
	'Failed to create company': 'Falha ao criar empresa',
	'User access not found': 'Permissão do usuário não encontrada',
	'Failed to create user permission': 'Falha ao criar permissão de usuário',
	'Information saved successfully': 'Informação salva com sucesso',
	'Vibration saved successfully': 'Vibração salva com sucesso',
	'Reaction saved successfully': 'Reação salva com sucesso',
	'Effort saved successfully': 'Esforço salvo com sucesso',
	'Sector created successfully': 'Setor criado com sucesso',
	'Risk ranges deleted successfully': 'Faixas de risco excluídas com sucesso',
	'Risk ranges created successfully': 'Faixas de risco criadas com sucesso',
	'Payment made successfully': 'Pagamento realizado com sucesso',
	'Payment being processed': 'Pagamento sendo processado',
	'Plan successfully canceled': 'Plano cancelado com sucesso',
	'Successfully renewed plan': 'Plano renovado com sucesso',
	'We received your order, we are processing your payment': 'Recebemos seu pedido, estamos processando seu pagamento',
	'Customer data not found': 'Dados do cliente não encontrados',
	'Failed to send email to recipient': 'Falha ao enviar e-mail ao destinatário',
	'Storage already created': 'Armazenamento já foi criado',
	'Storage not found': 'Armazenamento não encontrado',
	'Failed to create storage bucket': 'Falha ao criar bucket de armazenamento',
	'Failed to delete storage bucket': 'Falha ao deletar bucket de armazenamento',
	'Failed to download data': 'Falha no download de dados',
	'Failed to delete file': 'Falha ao deletar arquivo',

	// ACTION PLANS
	'Action plans': 'Planos de ação',
	'Do you want to delete this action plan?': 'Gostaria de remover este plano de ação?',
	'Action plan created successfully': 'Plano de ação criado com sucesso',
	'Create action plan': 'Criar plano de ação',
	'Please enter a valid description': 'Por favor, digite uma descrição válida',
	'Action plan description': 'Descrição do plano de ação',
	'Please enter a valid action plan description': 'Por favor, insira uma descrição válida do plano de ação',
	'Person responsible': 'Responsável',
	'Who is responsible for this action plan?': 'Quem é o responsável por este plano de ação?',
	Deadline: 'Prazo',
	'Relate to workstation/complaint': 'Relacionar com posto de trabalho/queixa',
	Complaint: 'Queixa',
	'Select a complaint': 'Selecione uma queixa',
	'Finish editing your other task first': 'Primeiro finalize a edição da outra tarefa',
	'Add a new task': 'Adicione uma nova tarefa',
	History: 'Histórico',
	'To Do': 'A fazer',
	Doing: 'Fazendo',
	Done: 'Feito',
	'TO DO': 'A FAZER',
	DOING: 'FAZENDO',
	DONE: 'FEITO',
	'Finish by: ': 'Concluir até: ',
	'On time': 'No prazo',
	Overdue: 'Atrasado',
	'Finishing in a week': 'Finalizando em uma semana',
	'Person responsible updated': 'Responśavel pelo plano de ação atualizado',
	'Deadline updated': 'Prazo do plano de ação atualizado',
	'Task created': 'Tarefa criada',
	'Task deleted': 'Tarefa removida',
	'Task renamed': 'Tarefa renomeada',
	'Task checked': 'Tarefa concluída',
	'Task unchecked': 'Tarefa desfeita',
	'Action plan created': 'Plano de ação criado',
	'Action plan renamed': 'Plano de ação renomeado',
	'Status changed': 'Status alterado',
	'Deadline changed': 'Prazo alterado',
	'Worker responsible changed': 'Responsável alterado',
	'by ': 'por ',
	'Successfully updated action plan data': 'Dados do plano de ação atualizados com sucesso',
	'Checklists in this workstation': 'Checklists neste posto de trabalho',
	'Global grade': 'Classificação global',
	'Global Grade': 'Classificação Global',
	'Employee already registered with this license plate': 'Funcionário já registrado com essa matrícula',

	'Successfully consolidated data': 'Dados consolidados com sucesso',
	'Successfully desconsolidated data': 'Dados desconsolidados com sucesso',
	'Failed to execute the action': 'Falha ao executar a ação',
	'Desconsolidate data': 'Desconsolidar dados',
	'Consolidate data': 'Consolidar dados',

	// Checklist EI
	'Industrial Engineering Checklist - Ergonomic work analysis':
		'Checklist Engenharia Industrial - Análise ergonômica do trabalho',
	SOP: 'SOP',
	'TCM ID': 'ID TCM',
	'Analysis review': 'Revisão de análise',
	'Field is required': 'Campo é obrigatório',
	'Must be a valid number': 'Deve ser um número',
	'TCM Structure': 'Estrutura TCM',
	'Enter the data related to TCM and speed factor below':
		'Insira os dados relacionados ao TCM e fator de velocidade abaixo',
	'Basic positions': 'Posições básicas',
	'Select a basic position': 'Selecione uma posição básica',
	Posture: 'Postura',
	'Select a posture': 'Selecione uma postura',
	'Weight Range': 'Faixa de peso',
	'Select a weight range': 'Selecione uma faixa de peso',
	TCM: 'TCM',
	'Speed factor': 'Fator de velocidade',
	'Select a speed factor': 'Selecione um fator de velocidade',
	Seated: 'Sentado',
	Standing: 'Em pé',
	Kneeling: 'Ajoelhado',
	'Lying down': 'Deitado',
	Walking: 'Andando',
	'Normal (Trunk almost stopped)': 'Normal (Tronco quase parado)',
	'Normal (Trunk in motion)': 'Normal (Tronco em movimento)',
	'Uncomfortable (Trunk almost stopped)': 'Desconfortável (Tronco quase parado)',
	'Uncomfortable (Trunk in motion)': 'Desconfortável (Tronco em movimento)',
	'On the surface': 'No plano',
	'Going up or down': 'Subindo ou descendo',
	'Pulling or pushing carts': 'Puxando ou empurrando carrinhos',
	'Slow speed': 'Velocidade lenta',
	'Normal speed': 'Velocidade normal',
	'Accelerated speed': 'Velocidade acelerada',
	'Slow speed (0.5)': 'Velocidade lenta (0.5)',
	'Normal speed (1)': 'Velocidade normal (1.0)',
	'Accelerated speed (1.5)': 'Velocidade acelerada (1.5)',
	'Time correction': 'Correção do tempo',
	'The record id must be 7 characters long': 'O ID da matrícula deve ter 7 caracteres',
	'Heads up! want to delete this file?': 'Atenção! deseja excluir este arquivo?',
	'Activate worker': 'Ativar funcionário',
	'Do you want to activate this employee?': 'Deseja ativar esse funcionário?',
	'Worker activated successfully': 'Trabalhador ativado com sucesso',
	'Worker successfully deactivated': 'Trabalhador desativado com sucesso',
	'Do you want to deactivate this employee?': 'Você quer desativar esse funcionário?',
	'Invalid file': 'Arquivo inválido',
	'Operation cancelled': 'operação cancelada',
	'Failed to export CSV': 'Falha ao exportar CSV',
	'Export CSV': 'Exportar CSV',
	files: 'arquivos',
	workers: 'funcionários',
	'There is no data matching the filter': 'Não há dados correspondentes ao filtro',
	'Wait for the lines to load': 'Aguarde o carregamento das linhas',
	health_matrix: 'matriz_saude',
	medical_certificates: 'atestados_medicos',
	complaints: 'queixas',
	'Error loading file': 'Erro ao carregar arquivo',
	'Sector not found on line': 'Setor não encontrado na linha',
	'Line not found on line': 'Linha não encontrada na linha',
	'Workstation not found on line': 'Posto de trabalho não encontrado na linha',
	'CIDM not found on line': 'CIDM não encontrado na linha',
	'Worker not found on line': 'Trabalhador não encontrado na linha',
	'Invalid date on line': 'Data inválida na linha',
	'Report name': 'Nome do relatório',
	'Invalid request data': 'Dados de solicitação inválidos',
	'Enter company name': 'Digite o nome da planta industrial',
	'Enter report name': 'Digite o Nome do relatório',
	'Failed to fetch file list': 'Falha ao buscar a lista de arquivos',
	Report: 'Relatório',
	'Select the reference date': 'Selecione a data de referência',
	'Select the report date': 'Selecione a data do relatório',
	'File name': 'Nome do arquivo',
	Analyst: 'Analsita',
	'Collection date': 'Data de coleta',
	Finish: 'Finalizar',
	'Report created successfully': 'Relatório criado com sucesso',
	'View files': 'Ver arquivos',
	'Dowload report': 'Baixar relatório',
	'Delete report': 'Deletar relatório',
	'Do you want to delete this report?': 'Você deseja deletar este relatório?',
	'Insert the activity': 'Insira a atividade',
	'Enter the address': 'Insira um endereço',
	'Enter the zip code': 'Insira um CEP',
	'Enter the district': 'Insira um bairro',
	'Enter the city': 'Insira uma cidade',
	'Enter the state': 'Insira um estado',
	'Enter the country': 'Insira um país',
	'Enter the degree of risk': 'Insira um grau de risco',

	Images: 'Imagens',
	'Image deleted successfully': 'Imagem excluída com sucesso',
	'Failed to delete image': 'Falha ao excluir imagem',
	'Image upload': 'Upload de imagens',
	'Upload file': 'Adicionar arquivo',
	'Upload images of the activity being evaluated': 'Carregue imagens da atividade sendo avaliada',
	Responsible: 'Responsável',
	day: 'dia',
	days: 'dias',
	Tasks: 'Tarefas',
	Comment: 'Comentário',
	'Comment updated successfully': 'Comentário atualizado com sucesso',

	'Failed to fetch reports': 'Falha ao buscar relatórios',
	'Failed to create report': 'Falha ao criar relatório',
	'Failed to update report': 'Falha ao atualizar relatório',
	'Failed to delete report': 'Falha ao excluir relatório',

	'Start date': 'Data de início',
	Update: 'Atualizar',
	Details: 'Detalhes',
	'Employee history': 'Histórico de funcionários',
	'New restriction': 'Nova restrição',
	'Type of restriction': 'Tipo de restrição',
	'Select the type of restriction': 'Selecione o tipo de restrição',
	'Restriction start date': 'Data de início da restrição',
	'Restriction end date': 'Data de término da restrição',
	'Description of the restriction': 'Descrição da restrição',
	'Do you want to delete this restriction?': 'Você deseja excluir esta restrição?',
	'Update restriction': 'Atualizar restrição',
	'Delete restriction': 'Excluir restrição',
	Restrictions: 'Restrições',
	'Add a new restriction': 'Adicionar uma nova restrição',
	'Edit restriction': 'Editar restrição',
	Inactive: 'Inativo',
	'Temporary restriction': 'Restrição temporária',
	'Indefinite restriction': 'Restrição indefinida',
	'Restriction until': 'Restrição até',
	'Worker was created': 'Funcionário criado',
	'Worker Creation': 'Criação de funcionário',
	'Undetermined restriction': 'Restrição indefinida',
	'Add informations': 'Adicionar informações',
	'No history available': 'Nenhuma histórico disponível',
	Restricted: 'Restrito',
	'Do you really want to deactivate the employee': 'Deseja mesmo desativar funcionário',
	'Worker restriction updated successfully': 'Restrição de funcionário atualizada com sucesso',
	'Worker restriction deleted successfully': 'Restrição de funcionário excluída com sucesso',
	'Worker restriction created successfully': 'Restrição de funcionário criada com sucesso',
	'Do you really want to activate the employee': 'Deseja mesmo ativar funcionário',

	'Video selection': 'Seleção de vídeos',
	'Select the video files for the operations that make up the workstation below':
		'Selecione abaixo os arquivos de vídeo referentes às operações que compõem o posto de trabalho',
	'Search for a file': 'Procure por um arquivo',
	'Selected files': 'Arquivos selecionados',
	to: 'até',
	until: 'até',
	Filter: 'Filtrar',
	'Chrono-Analysis': 'Cronoanálise',
	Platform: 'Plataforma',
	Analysis: 'Análise',
	'Created date': 'Data de criação',
	'Items per page': 'Itens por página',
	Engineering: 'Engenharia Industrial',
	Chronoanalysis: 'Cronoanálise',
	Operation: 'Operação',
	'Add item': 'Adicionar item',
	'Analysis date': 'Data da análise',
	'Physiological factor': 'Fator de necessidades fisiológicas',
	'Fatigue factor': 'Fator de fadiga',
	'Timing system': 'Sistema de tempo',
	'Operation and model': 'Operação e produto',
	'Fill in the specific characteristics of the operations and relate them to the product models':
		'Preencha as características específicas das operações e relacione essas com os produtos',
	'Number of operators': 'Quantidade de operadores',
	'Set the number of operators': 'Informe a quantidade de operadores',
	'Select the fatigue factor': 'Selecione o fator de fadiga',
	'Rest factor': 'Fator de descanso',
	'Evaluate each operation on its rest factor': 'Avalie cada operação em seu fator de descanso',
	'Normal (Trunk almost stationary)': 'Normal (Tronco quase parado)',
	'Normal (Trunk in movement)': 'Normal (Tronco em movimento)',
	'Uncomfortable (Trunk almost stationary)': 'Desconfortável (Tronco quase parado)',
	'Uncomfortable (Trunk in movement)': 'Desconfortável (Tronco em movimento)',
	Weight: 'Peso',
	Speed: 'Velocidade',
	'2kg to 10kg': '2kg até 10kg',
	'10kg to 20kg': '10kg até 20 kg',
	'20kg to 25kg': '20kg to 25kg',
	Sitting: 'Sentado',
	'Select a required field': 'Selecione o campo requisitado',
	'Operation updated successfully': 'Operação atualizada com sucesso',
	'Operations was set in files successfully': 'As operações foram inseridas nos arquivos com sucesso',
	'Chronoanalysis created successfully': 'Cronoanálise criada com sucesso',
	'Chronoanalysis updated successfully': 'Cronoanálise atualizada com sucesso',
	'Operation and product': 'Operação e produto',
	'Fill in the specific characteristics of the operations and relate them to the product':
		'Preencha as características específicas das operações e relacione essas com os produtos',
	'Insert the report name': 'Insira o nome do relatório',
	'Select the collection date': 'Insira a data de coleta',
	'Select the analysis date': 'Insira a data de análise',
	'Select the industrial site': 'Selecione a planta industrial',
	'Select the timing system': 'Selecione o sistema de tempo',
	'Select the line': 'Selecione a linha',
	'Select the cost center': 'Selecione o centro de custo',
	'Select the company': 'Selecione a empresa',
	'Select the evaluator': 'Selecione o analista',
	'Added value': 'Agregação de valor',
	'Analyze the value added to each operation': 'Analise os valores agregados a cada operação',
	'The following files were used in the analysis': 'Os seguintes arquivos foram utilizados na análise',
	Show: 'Apresentar',
	'On flat': 'No plano',
	Length: 'Duração',
	'Total time': 'Tempo total',
	'Time evaluated': 'Tempo avaliado',
	'Put together': 'Colocar junto',
	'Passing from one hand to another': 'Passar de uma mão para a outra',
	'Identify product': 'Identificar produto',
	'Remove identification': 'Tirar identificação',
	Insert: 'Inserir',
	Walk: 'Andar',
	Push: 'Empurrar',
	Pull: 'Puxar',
	Wait: 'Esperar',
	Stop: 'Parar',
	Rotate: 'Rotacionar',
	Press: 'Pressionar',
	Welding: 'Soldar',
	Organize: 'Organizar',
	Accumulate: 'Acumular',
	Measure: 'Medir',
	Delay: 'Atrasar',
	Watch: 'Assistir',
	Split: 'Dividir',
	'Folding material': 'Dobrar material',
	Drilling: 'Furar',
	'Move down': 'Mover para baixo',
	'Move up': 'Mover para cima',
	'Part configuration': 'Configuração de partes',
	Rework: 'Refazer',
	'Try to fix': 'Tentar fixar',
	Count: 'Contar',
	Grab: 'Pegar',
	Relocate: 'Realojar',
	'Try to deposit': 'Tentar depositar',
	'Try to place': 'Tentar colocar',
	Paint: 'Pintar',
	'Re-stacking': 'Reempilhar',
	Turn: 'Virar',
	Resume: 'Retomar',
	'Try to tighten': 'Tente apertar',
	Screwing: 'Parafusar',
	Change: 'Mudar',
	'Push cart': 'Empurrar com carrinho',
	Fit: 'Encaixar',
	'Leftover material': 'Sobrar material',
	Inspect: 'Inspecionar',
	Clean: 'Limpar',
	Glue: 'Colar',
	Tighten: 'Apertar',
	'Cutting material': 'Cortar material',
	Return: 'Retornar',
	Hammering: 'Martelar',
	Hold: 'Segurar',
	Packing: 'Embalar',
	Select: 'Selecionar',
	Looking: 'Procurar',
	'Give back': 'Devolver',
	Adjust: 'Ajustar',
	'Try to insert': 'Tentar inserir',
	Transfer: 'Transferir',
	Connect: 'Conectar',
	Place: 'Posicionar',
	'Pre-treatment': 'Pré-tratamento',
	Unbutton: 'Desabotoar',
	Renew: 'Renovar',
	Repackaging: 'Reembalar',
	'Action classification exceeded the total time': 'Classificação da ação ultrapassou o tempo total',
	'Action created successfully': 'Ação criada com sucesso',
	'Action deleted successfully': 'Ação deletada com sucesso',
	Saturation: 'Saturação',
	'Analyze the saturation of product models': 'Analise a saturação dos produtos',
	'Set the volume': 'Insira o volume',
	'Physiological needs factor': 'Fator de necessidades fisiológicas',
	'General results of the workstation': 'Resultados gerais do posto',
	'Operating time for balancing (s)': 'Tempo de operação para balanceamento (s)',
	'Consolidate report': 'Consolidar análise',
	Information: 'Informação',
	'Time system': 'Sistema de tempo',
	'Download PDF': 'Baixar PDF',
	'Operations and files': 'Operações e arquivos',
	'Cycle time (s)': 'Tempo ciclo (s)',
	'Cycle time (min)': 'Tempo ciclo (min)',
	'Operations by product': 'Operações por produto',
	'Operations summary': 'Resumo por operação',
	'Timing (s)': 'Tomada de tempo (s)',
	'Timing (min)': 'Tomada de tempo (min)',
	'Product summary': 'Resumo por produto',
	'Time for balancing': 'Tempo para balanceamento',
	'Technical time (with factors)': 'Tempo técnico (com fatores)',
	Incidence: 'Incidência',
	'Time on condition': 'Tempo na condição',
	Comments: 'Comentários',
	'Ops... something happened!': 'Ops... algo aconteceu!',
	'Select the length': 'Selecione a duração',
	'Select the repetitions': 'Selecione as repetições',
	'Set the total time': 'Insira o tempo total',
	'Chronoanalysis report consolidated successfully': 'Cronoanálise consolidada com sucesso',
	'Chronoanalysis deleted successfully': 'Cronoanálise deletada com sucesso',
	'Comments was saved successfully': 'Comentários salvos com sucesso',
	'Evaluator created successfully': 'Analista criado com sucesso',
	'Line created successfully': 'Linha criada com sucesso',
	'Operation created successfully': 'Operação criada com sucesso',
	'Platform created successfully': 'Plataforma criada com sucesso',
	'Product created successfully': 'Produto criado com sucesso',
	'Workstation created successfully': 'Posto de trabalho criado com sucesso',
	'Step not found': 'Dados não encontrados',
	'Chronoanalysis not found': 'Cronoanálise não encontrada',
	'Chronoanalysis not created': 'Cronoanálise não criada',
	'Chronoanalysis not updated': 'Cronoanálise não atualizada',
	'Chronoanalysis not delete': 'Cronoanálise não deletada',
	'Chronoanalysis not consolidated': 'Cronoanálise não consolidada',
	'Operation not found': 'Operação não encontrada',
	'Operation not created': 'Operação não criada',
	'Action classification not found': 'Classificação da ação não encontrada',
	'Action classification not created': 'Classificação da ação não criada',
	'Action classification not delete': 'Classificação da ação não deletada',
	'Rest factores parameters not found': 'Fatores de descanso não encontrados',
	'Comments not updated': 'Comentários não atualizados',
	'Product updated successfully': 'Produto atualizado com sucesso',
	'Chrono analysis report created successfully': 'Cronoanálise criada com sucesso',
	'Chrono analysis report updated successfully': 'Cronoanálise atualizada com sucesso',
	'Chrono analysis report deleted successfully': 'Cronoanálise deletada com sucesso',
	'Chrono analysis consolidated successfully': 'Cronoanálise consolidada com sucesso',
	'Comments report updated successfully': 'Comentários atualizados com sucesso',
	'Failed to consolidate report': 'Falha ao consolidar o relatório',
	'Chronoanalysis report updated successfully': 'Relatório de cronoanálise atualizado com sucesso',
	Deconsolidate: 'Desconsolidar',
	'Failed to generate the report': 'Falha ao gerar o relatório',
	Results: 'Resultados',
	'Evaluated time is different of total time': 'O tempo avaliado é diferente do tempo total',
	'Product already registered': 'Produto já registrado',
	'Failed to create product': 'Falha ao criar produto',
	'Failed to assign the product to the workstation': 'Falha ao atribuir o produto à estação de trabalho',
	'Product update successfully': 'Atualização do produto realizada com sucesso',
	'Failed to update product': 'Erro ao atualizar produto',
	'Product deleted successfully': 'Produto deletado com sucesso',
	'Failed to remove product': 'Falha ao remover o produto',

	'Failed to fetch medical certificates': 'Falha ao buscar os certificados médicos',
	'Oops, something happened!': 'Ops, aconteceu alguma coisa!',
	'Failed to send message to queue': 'Falha ao enviar mensagem para a fila',
	'File already processed': 'Arquivo já processado',
	'File already queued': 'Arquivo já em fila',
	'Message sent successfully': 'Mensagem enviada com sucesso',

	'Product removed successfully': 'Produto removido com sucesso',
	'Search for a product': 'Procure por um produto',
	Products: 'Produtos',
	'Remaining time': 'Tempo restante',

	'Operating time for balancing (min)': 'Tempo de operação para balanceamento (min)',
	'Technical time (min)': 'Tempo técnico (min)',
	minutes: 'minutos',

	// MTM
	'Fill in the specific characteristics of the operations and relate them to the products':
		'Preencha as características específicas das operações e relacione essas com os produtos',
	'MTM operations updated successfully': 'Operações MTM atualizadas com sucesso',
	'MTM created successfully': 'MTM criado com sucesso',
	'Operations created successfully': 'Operações criadas com sucesso',
	'General results of the line': 'Resultados gerais da linha',
	'Line total time (min)': 'Tempo total da linha (min)',
	'Time of the file (min)': 'Tempo do vídeo (min)',
	'Operation total time': 'Tempo total da operação',
	'Aggregation value': 'Agregação de valor',
	Quantity: 'Quantidade',
	Coefficient: 'Coeficiente',
	Seconds: 'Segundos',
	Minutes: 'Minutos',
	Hours: 'Horas',
	'Value-added': 'Agregação de valor',
	Meaning: 'Significado',
	'Search for a activity': 'Procure uma atividade',
	'Add activities to your operations': 'Adicione atividades em suas operações',
	'MTM operation created successfully': 'Operação MTM criada com sucesso',
	'MTM operation updated successfully': 'Operação MTM atualizada com sucesso',
	'MTM operation deleted successfully': 'Operação MTM deletada com sucesso',
	'MTM updated successfully': 'MTM atualizado com sucesso',
	'MTM deleted successfully': 'MTM deletado com sucesso',
	'Total time (min)': 'Tempo total (min)',
	Time: 'Tempo',
	'MTM not found': 'MTM não encontrado',
	'MTM not created': 'MTM não criado',
	'MTM not updated': 'MTM não atualizado',
	'MTM not deleted': 'MTM não deletado',
	'MTM successfully deleted': 'MTM deletado com sucesso',
	'MTM not consolidated': 'MTM não consolidado',
	'Failed to update MTM report': 'Falha ao atualizar o relatório MTM',
	'MTM code not found': 'Código MTM não encontrado',
	'MTM operation already created': 'Operação MTM já criada',
	'MTM operation not found': 'Operação MTM não encontrada',
	'MTM operation not created': 'Operação MTM não criada',
	'MTM operation not updated': 'Operação MTM não atualizada',
	'MTM operation not deleted': 'Operação MTM não deletada',
	'TMU code': 'Código TMU',
	Sequence: 'Sequência',
	'Technical total time': 'Tempo total técnico',
	'Failed to generate report data': 'Falha ao gerar dados do relatório',
	'AI Suggestion': 'Sugestão da AI',
	'AI Suggestion for walking': 'Sugestão da AI para caminhar',
	'AI Suggestion for bent': 'Sugestão da AI para inclinar',
	'AI Suggestion for kneeling': 'Sugestão da AI para ajoelhar',
	'AI Suggestion for sitting': 'Sugestão da AI para sentar',
	'AI Suggestion for standing': 'Sugestão da AI para ficar em pé',
	'Manufacturing Engineering': 'Engenharia de Manufatura',

	Break: 'Pausa',
	Rotation: 'Rodízio',
	'Evaluation of the rotation': 'Avaliação do rodízio',
	'Select the activities that will make up the rotation and define the order and frequency':
		'Selecione as atividades que vão compor o rodízio e defina ordem e frequência',
	'Select the operations that will make up the rotation and define the order and frequency':
		'Selecione as operações que vão compor o rodízio e defina ordem e frequência',
	'Exposure of selected activities': 'Exposição das atividades selecionadas',
	'Exposure of selected operations': 'Exposição das operações selecionadas',
	'Select the activity': 'Selecione a atividade',
	'Activity time': 'Tempo da atividade',
	'Overall exposure': 'Exposição geral',
	Goal: 'Objetivo',
	Goals: 'Objetivos',
	'General informations': 'Informações gerais',
	'Pause Identification': 'Identificação da pausa',
	'Time (minutes)': 'Tempo (minutos)',
	'Provide the time': 'Informe o tempo',
	'Provide a description': 'Informe uma descrição',
	'Provide a frequency': 'Informe a frequência',
	Required: 'Obrigatório',
	'Risk Range': 'Faixa de risco',
	'Risk range': 'Faixa de risco',
	Evaluator: 'Avaliador',
	'Creation date': 'Data de criação',
	'New Report': 'Nova avaliação',
	'Quantity of times that the activity repeats': 'Quantidade de vezes que a atividade se repete',
	'Add at least one activity': 'Adicione pelo menos uma atividade',
	'Overall results of the rotation': 'Resultados geral do rodízio',
	'Activities and videos': 'Atividades e vídeos',
	'Operations and videos': 'Operações e vídeos',
	'Minimum acceptable exposure': 'Exposição aceitável mínima',
	'Total cycle time': 'Tempo total do ciclo',
	'Exposure found': 'Exposição encontrada',
	Exposure: 'Exposição',
	Pause: 'Pausa',
	'General Informations': 'Informações gerais',
	'Enter the data related to the new analysis below': 'Insira abaixo os dados relacionados a nova avaliação',
	'Indicate the minimum acceptable exposure levels for the rotation':
		'Indique os níveis de exposição aceitável mínimos para o rodízio',
	'Minimum acceptable exposure level per body part': 'Nível de exposição aceitável mínima por parte do corpo',
	'Select the items you want to present in your document':
		'Selecione os itens que você deseja apresentar em seu documento',
	'Generate document': 'Gerar documento',
	'Video files': 'Arquivos de vídeo',
	'File operations must have unique names for same report':
		'Operações de arquivo devem ter nomes únicos para o mesmo relatório',
	'Fill the required fields': 'Preencha os campos obrigatórios',
	'Provide a activity': 'Informe a atividade',
	'Provide an operation': 'Informe a operação',
	'Files added to report successfully': 'Arquivos adicionados ao relatório com sucesso',
	'Activity created successfully': 'Atividade criada com sucesso',
	'Activity updated successfully': 'Atividade atualizada com sucesso',
	'Report updated successfully': 'Avaliação atualizada com sucesso',
	'Activity deleted successfully': 'Atividade excluída com sucesso',
	Delete: 'Excluir',
	Edit: 'Editar',
	'Report deleted successfully': 'Avaliação excluída com sucesso',
	'Heads up! Want to delete this report?': 'Atenção! Deseja excluir este relatório?',
	'Heads up! Want to delete this activity?': 'Atenção! Deseja excluir esta atividade?',
	'Heads up! Want to delete this operation?': 'Atenção! Deseja excluir esta operação?',
	'Operation time': 'Tempo da operação',
	'Select the operation': 'Selecione a operação',
	'Select a file': 'Selecione um arquivo',
	'End date cannot be less than the start date': 'Data final não pode ser menor que a data inicial',
	'Select the end date': 'Selecione a data final',
	'Select the start date': 'Selecione a data inicial',
	'Are you sure you want to delete the report': 'Você tem certeza que deseja excluir o relatório',
	'Volume (daily)': 'Volume (diário)',
	'Total time in minutes': 'Tempo total em minutos',
	'Available work shift time': 'Tempo disponível do turno de trabalho',
	'Not restricted': 'Não restrito',
	'Highest risk found': 'Maior risco encontrado',
	'General overview': 'Visão geral',

	'Rating history': 'Histórico de avaliações',
	'Workstation selection': 'Seleção do posto de trabalho',
	'Select period': 'Selecionar período',
	Day: 'Dia',
	Week: 'Semana',
	Month: 'Mês',
	Quarter: 'Trimestre',
	Semester: 'Semestre',
	Year: 'Ano',
	Granularity: 'Granularidade',
	week: 'semana',
	month: 'mês',
	quarter: 'trimestre',
	semester: 'semestre',
	year: 'ano',
	'Total jobs and risk assessments': 'Total de postos de trabalho e avaliações de risco'
};

export default dictionary;
