import React from 'react';
import { Row, Col, Form } from 'antd';
import { Title } from '@/components/Typography';

import { Filters } from './Filters';
import { Period } from './Fields/Period';
import { DownloadCSV } from './DownloadCSV';
import { HistoryChart as Chart } from './Chart';
import { Granularity } from './Fields/Granularity';

import { CustomColEnd, ChartWrapper, ChartContainer, GranularityContainer } from './styles';

const { useFormInstance } = Form;

export function HistoryChart() {
	const form = useFormInstance();

	return (
		<Form form={form}>
			<Row gutter={[0, 15]}>
				<Col span={24}>
					<Title level={4}>Rating history</Title>
				</Col>
				<Col xxl={4} xl={5} lg={6} md={7} sm={24}>
					<Filters />
				</Col>
				<Col xxl={20} xl={19} lg={18} md={17} sm={24}>
					<ChartWrapper>
						<Row gutter={[20, 15]}>
							<Col span={8}>
								<Period formName={['history_chart']} />
							</Col>
							<CustomColEnd span={14}>
								<GranularityContainer>
									<Granularity formName={['granularity']} />
								</GranularityContainer>
							</CustomColEnd>
							<CustomColEnd span={2}>
								<DownloadCSV />
							</CustomColEnd>
						</Row>
						<ChartContainer>
							<Chart />
						</ChartContainer>
					</ChartWrapper>
				</Col>
			</Row>
		</Form>
	);
}
