import styled from 'styled-components';

interface ColorBoxProps {
	$color: string;
}

export const LegendWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 16px 0px;
`;

export const LegendRiskWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	gap: 5px;

	.ant-typography {
		color: #262626 !important;
		margin-bottom: 0px !important;
	}
`;

export const LegendTitle = styled.span`
	font-size: 14px;
	font-weight: 600;
	color: #262626;
`;

export const LegendItems = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

export const LegendItem = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;

	&.text-400 .ant-typography {
		font-weight: 400 !important;
	}

	.ant-typography {
		color: #262626 !important;
		margin-bottom: 0px !important;
	}
`;

export const ColorBox = styled.div<ColorBoxProps>`
	width: 30px;
	height: 30px;
	border-radius: 4px;
	background-color: ${({ $color }) => $color};
`;

export const WomenBox = styled.div`
	width: 50px;
	height: 24px;
	border-radius: 60px;
	background-color: #9cb5fa;
`;

export const PwdBox = styled.div`
	width: 50px;
	height: 24px;
	border-radius: 3px;
	background-color: #2d4ed4;
`;

export const RestrictedBox = styled.div`
	width: 54px;
	height: 32px;
	border-radius: 3px;
	background-color: #152770;
`;
