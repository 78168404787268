import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useHistory, useLocation } from 'react-router-dom';

import { Title } from '@/components/Typography';
import { useApplicationContext } from '@/context/application';
import { useGetCompanies, useGetLines, useGetSectors, useGetWorkstations } from '@/hooks';

import { Container, FilterGroup, Label, CustomSelect, CustomInput } from './styles';
import { message } from 'antd';

const { Option } = CustomSelect;

export function Filters() {
	const history = useHistory();
	const location = useLocation();
	const { organization } = useApplicationContext();
	const searchParams = new URLSearchParams(location.search);

	const company_id = searchParams.get('company');
	const sector_id = searchParams.get('sector');
	const line_id = searchParams.get('line');

	const {
		data: companies,
		isLoading: companiesLoading,
		isError: companiesError
	} = useGetCompanies({ organization_id: organization?.id });
	const {
		data: sectors,
		isLoading: sectorsLoading,
		isError: sectorsError
	} = useGetSectors({
		organization_id: organization?.id,
		company_id: company_id || ''
	});
	const {
		data: lines,
		isLoading: linesLoading,
		isError: linesError
	} = useGetLines({
		organization_id: organization?.id,
		company_id: company_id || '',
		sector_id: sector_id || ''
	});
	const {
		data: workstations,
		isLoading: workstationsLoading,
		isError: workstationsError
	} = useGetWorkstations({
		organization_id: organization?.id,
		company_id: company_id || '',
		sector_id: sector_id || '',
		line_id: line_id || ''
	});

	function handleCompanyChange(value: string, option: any) {
		const params = new URLSearchParams(location.search);
		params.delete('line');
		params.delete('sector');
		params.delete('line_name');
		params.delete('workstation');
		params.delete('sector_name');
		params.delete('workstation_name');

		if (value) {
			params.set('company', value);
			params.set('company_name', option.children);
		} else {
			params.delete('company');
			params.delete('company_name');
		}

		history.replace({
			pathname: location.pathname,
			search: params.toString()
		});
	}

	function handleSectorChange(value: string, option: any) {
		const params = new URLSearchParams(location.search);
		params.delete('line');
		params.delete('line_name');
		params.delete('workstation');
		params.delete('workstation_name');

		value ? params.set('sector', String(value)) : params.delete('sector');
		option ? params.set('sector_name', option.children) : params.delete('sector_name');
		history.replace({
			pathname: location.pathname,
			search: params.toString()
		});
	}

	function handleLineChange(value: string, option: any) {
		const params = new URLSearchParams(location.search);
		params.delete('workstation');
		params.delete('workstation_name');

		value ? params.set('line', String(value)) : params.delete('line');
		option ? params.set('line_name', option.children) : params.delete('line_name');
		history.replace({
			pathname: location.pathname,
			search: params.toString()
		});
	}

	function handleWorkstationChange(value: string, option: any) {
		const params = new URLSearchParams(location.search);

		value ? params.set('workstation', String(value)) : params.delete('workstation');
		option ? params.set('workstation_name', option.children) : params.delete('workstation_name');

		history.replace({
			pathname: location.pathname,
			search: params.toString()
		});
	}

	if (companiesError || sectorsError || linesError || workstationsError) {
		message.error(I18n.get('Error loading hierarchy'));
		return null;
	}

	return (
		<Container>
			<Title level={5}>{I18n.get('Workstation selection')}</Title>
			<FilterGroup>
				<Label>{I18n.get('Company')}</Label>
				<CustomInput value={organization?.name} disabled />
			</FilterGroup>
			<FilterGroup>
				<Label>{I18n.get('Industrial site')}</Label>
				<CustomSelect
					allowClear
					showSearch
					value={company_id || ''}
					loading={companiesLoading}
					optionFilterProp="children"
					placeholder={I18n.get('Select a company')}
					onChange={(value, option) => handleCompanyChange(value as string, option)}
				>
					{companies?.map((company) => (
						<Option key={company.id} value={company.id}>
							{company.name}
						</Option>
					))}
				</CustomSelect>
			</FilterGroup>
			<FilterGroup>
				<Label>{I18n.get('Cost center')}</Label>
				<CustomSelect
					allowClear
					showSearch
					disabled={!company_id}
					value={sector_id || ''}
					loading={!!company_id && sectorsLoading}
					optionFilterProp="children"
					placeholder={I18n.get('Select a cost center')}
					onChange={(value, option) => handleSectorChange(value as string, option)}
				>
					{sectors?.map((sector) => (
						<Option key={sector.id} value={sector.id}>
							{sector.name}
						</Option>
					))}
				</CustomSelect>
			</FilterGroup>
			<FilterGroup>
				<Label>{I18n.get('Line')}</Label>
				<CustomSelect
					allowClear
					showSearch
					value={line_id || ''}
					disabled={!sector_id}
					loading={!!sector_id && linesLoading}
					optionFilterProp="children"
					placeholder={I18n.get('Select a line')}
					onChange={(value, option) => handleLineChange(value as string, option)}
				>
					{lines?.map((line) => (
						<Option key={line.id} value={line.id}>
							{line.name}
						</Option>
					))}
				</CustomSelect>
			</FilterGroup>
			<FilterGroup>
				<Label>{I18n.get('Workstation')}</Label>
				<CustomSelect
					allowClear
					showSearch
					disabled={!line_id}
					optionFilterProp="children"
					loading={!!line_id && workstationsLoading}
					value={searchParams.get('workstation') || ''}
					placeholder={I18n.get('Select a workstation')}
					onChange={(value, option) => handleWorkstationChange(value as string, option)}
				>
					{workstations?.map((workstation) => (
						<Option key={workstation.id} value={workstation.id}>
							{workstation.name}
						</Option>
					))}
				</CustomSelect>
			</FilterGroup>
		</Container>
	);
}
